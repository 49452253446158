import { useState, useEffect } from "react"
import { Row, Col, Badge, Spinner } from "reactstrap"
import WizardPlayer from "./audioEditorComp/WizardPlayer"

const GeneratedAd = (props) => {
    console.log(props)
    const [isExpanded, setIsExpanded] = useState(false)
    const [selectedTitleVoice, setSelectedTitleVoice] = useState("")
    const [selectedTitleMusic, setSelectedTitleMusic] = useState("")
    const [isMusicPlaying, setIsMusicPlaying] = useState(false)
    const [isVoicePlaying, setIsVoicePlaying] = useState(false)

    const handleGenerateAds = (id) => {
        if (!props.isLanguage) {
            props.regenerateContent(id)
        }
    }

    const handleExpand = (e) => {

        if (!e.target.closest('.ignorBut')) {
            setIsExpanded(!isExpanded)
        }

    }

    const [voiceTags, setVoiceTags] = useState([])
    const [musicTags, setMusicTags] = useState([])
    const formatTags = (vt, mt) => {

        const colorCodes = {
            3: "#bf7fff",
            5: "#aec6cf",
            2: "#ff7f7f",
            1: "#ffa94d",
            4: "#7fc7ff"
        }

        //voicetag
        if (vt) {
            const labelArr = vt.labels;
            let labelArrObject
            if (labelArr && labelArr.length > 0) {
                labelArrObject = labelArr.map((tag) => {
                    return {
                        label: tag.name,
                        value: tag.name,
                        textColor: colorCodes[tag.typeId]
                    }
                })
            } else {
                return null
            }

            setVoiceTags(labelArrObject)
        }
        //musictag
        if (mt) {
            const labelArr = mt.tags
            let labelArrObject

            if (labelArr && labelArr.length > 0) {
                const colorValues = Object.values(colorCodes)
                labelArrObject = labelArr.map((tag, index) => {
                    return {
                        label: tag.name,
                        value: tag.name,
                        textColor: colorValues[index % colorValues.length]
                    }
                })

            } else {
                return null
            }

            setMusicTags(labelArrObject)
        }

    }

    const populateTitles = () => {


        const voiceTemp = props.voices.find((voice) => voice?.voiceId == props.advert.voiceId)
        const musicTemp = props.music.find((song) => song?.id == props.advert.musicId)

        if (!voiceTemp) {
            setSelectedTitleVoice(null)
        } else {
            setSelectedTitleVoice(voiceTemp)
        }

        if (!musicTemp) {
            setSelectedTitleMusic(null)
        } else {
            setSelectedTitleMusic(musicTemp)
        }

        formatTags(voiceTemp, musicTemp)
    }


    useEffect(() => {
        populateTitles()
    }, [props.advert.voiceId, props.advert.musicId])

    useEffect(() => {
        if (props.advert && props.voices && props.voices.length > 0 && props.music && props.music.length > 0) {
            populateTitles()
        }
    }, [props.advert, props.voices, props.music])

    const handleSetupPreviewVoice = (id) => {
        setIsMusicPlaying((prevIsMusicPlaying) => !prevIsMusicPlaying);
        if (id) {
            const findSongUrl = props.music.find((song) => song.id === id).url
            props.handlePreviewVoice(findSongUrl)
        } else {
            props.handlePreviewVoice()
        }
    }

    const handleVoicePreview = (isVoice) => {
        if (!isVoicePlaying) {
            const findVoiceValue = props.voices.find((voice) => voice?.voiceId == props.advert.voiceId).voiceAiValue
            console.log("STUFF", findVoiceValue, isVoice, props.advert.id)
            props.handlePreviewVoice(findVoiceValue, isVoice, props.advert.id)
        } else {
            props.handlePreviewVoice()
        }

        setIsVoicePlaying(!isVoicePlaying)
    }

    useEffect(() => {
        setIsExpanded(props.isFirst)
    }, [props.isFirst])

    const [audioToPlay, setAudioToPlay] = useState(null)

    const handleAdvertAudio = () => {
        if (props.advert?.urls && props.advert?.urls.length > 0) {

            setAudioToPlay(props.advert.urls[0].url)
        } else {
            setAudioToPlay(props.advert)
        }
    }

    useEffect(() => {
        if (props.advert) {
            handleAdvertAudio()
        }
    }, [props.advert])

    return (
        <div>
            <div style={{ backgroundColor: "#404040", display: "flex", alignItems: "center", justifyContent: "center", gap: 15, width: "90%", margin: "auto", padding: 15 }}>
                <div style={{ width: "90%" }}>
                    {props.language && <span>{props.language}</span> }
                    <div style={{ backgroundColor: "" }}>
                        <WizardPlayer fileURL={audioToPlay} />
                    </div>
                </div>
            </div>
            <div style={{
                width: "90%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
            }} className="mt-2 mb-2">
                {!props.isLanguage &&
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", cursor: "pointer", backgroundColor: "rgba(225, 225, 225, 0.3)", alignItems: "center", paddingLeft: 10, paddingRight: 10 }} onClick={handleExpand}>
                        <p style={{ margin: 0 }}>{!props.isLanguage ? "Ad" : "Translation"} Preview</p>
                        {!isExpanded ? <i className="fas fa-chevron-down" /> : <i className="fas fa-chevron-up" />}
                    </div>
                }
                {isExpanded &&
                    <div style={{ disaply: "flex", width: "100%", backgroundColor: "rgba(225, 225, 225, 0.4)", paddingLeft: "2.5%", paddingRight: "2.5%" }} className="mb-2 pt-2">
                        <Row className="justify-content-between">
                            <Col style={{ border: "", borderRadius: 0, padding: 0, marginLeft: 5, marginRight: 5 }} sm="">
                                <p style={{ margin: 0, backgroundColor: "rgb(64, 64, 64)", width: "100%", padding: 10 }}>Voice</p>
                                <div style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-around", backgroundColor: "rgba(64, 64, 64, 0.8)" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "", width: "50%", gap: 25 }}>
                                        <div>
                                            <p style={{ borderRadius: 35, minHeight: 35, maxHeight: 35, minWidth: 35, maxWidth: 35, backgroundColor: "transparent" }}></p>
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "50%" }}>
                                            <div>
                                                <p>{selectedTitleVoice ? selectedTitleVoice.voiceName : "No voice selected"}</p>
                                                <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                                    {voiceTags.map((e) => {
                                                        return (
                                                            <Badge
                                                                pill
                                                                style={{ color: "#fff", backgroundColor: e.textColor, border: e.textColor, margin: 0 }}
                                                            >{e.value}</Badge>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div>
                                                {props.loadingAudio && props.loadingAudio.isVoice && props.loadingAudio.id === props.advert.id ?
                                                    <div>
                                                        <Spinner />
                                                    </div> :
                                                        <i className={`${!isVoicePlaying ? "fa fa-play" : "fa fa-pause"}`} style={{ fontSize: 26, cursor: "pointer" }} onClick={() => handleVoicePreview(true)} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button onClick={() => props.setModalPage("voice", props.advert)} type="submit" className="buttonStyle buttonPrimary ignorBut">Change</button>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ border: "", borderRadius: 0, padding: 0, marginLeft: 5, marginRight: 5, backgroundColor: "rgba(64, 64, 64, 0.8)" }} sm="">
                                <p style={{ margin: 0, backgroundColor: "rgb(64, 64, 64)", width: "100%", padding: 10 }}>Music</p>
                                <div style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "", width: "50%", gap: 25 }}>
                                        <div>
                                            <p style={{ borderRadius: 35, minHeight: 35, maxHeight: 35, minWidth: 35, maxWidth: 35, backgroundColor: "transparent" }}></p>
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "50%" }}>
                                            <div>
                                                <p>{selectedTitleMusic ? selectedTitleMusic.name : "No music selected"}</p>
                                                <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                                    {musicTags.map((e) => {
                                                        return (
                                                            <Badge
                                                                pill
                                                                style={{ color: "#fff", backgroundColor: e.textColor, border: e.textColor, margin: 0 }}
                                                            >{e.value}</Badge>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div>
                                                <i className={`${!isMusicPlaying ? "fa fa-play" : "fa fa-pause"}`} style={{ fontSize: 26, cursor: "pointer" }} onClick={!isMusicPlaying ? () => handleSetupPreviewVoice(props.advert.musicId) : () => handleSetupPreviewVoice()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button onClick={() => props.setModalPage("music", props.advert)} type="submit" className="buttonStyle buttonPrimary ignorBut">Change</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-2">
                            <Col className="text-center">
                                {props.advert.updateReq &&

                                    <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
                                        {props.isLanguage &&
                                            <button onClick={() => handleGenerateAds()} type="submit" style={{ minWidth: "20%", backgroundColor: "transparent", minHeight: 40, maxHeight: 40, border: "2px solid #F33B30", boxShadow: "none", borderRadius: 5, color: "#FFF" }}>Delete</button>
                                        }
                                        <button className="ignorBut" onClick={() => handleGenerateAds(props.advert.id)} type="submit" style={{ minWidth: "20%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "2px solid transparent", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}> REGENERATE (1 CREDIT) {props.singleLoadId && props.singleLoadId == props.advert.id && <Spinner size="sm" />}</button>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        </div>
    )
}

export default GeneratedAd