import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Row, Col, Spinner } from "reactstrap";
import VoiceCard from "./VoiceCard";

const BStep2 = React.forwardRef((props, ref) => {

    const [alert, setAlert] = useState(null)

    const [selectedVoice, setSelectedVoice] = useState(null)
    const handleSelectVoice = (voice) => {
        setSelectedVoice(voice.voiceName)
        props.setBaseObject({ ...props.baseObject, defaultVoiceId: voice.id })
    }

    const [selectedVoiceUrl, setSelectedVoiceUrl] = useState(null)
    const [isVoicePreview, setIsVoicePreview] = useState(false)
    const handlePreviewVoice = (previewUrl) => {
        setIsVoicePreview(false)
        setSelectedVoiceUrl(previewUrl)
        setIsVoicePreview(true)
    }

    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            if (selectedVoice != null) {
                props.setTitleNumber(props.titleNumber + 1)
                return true
            }
            setAlert("Please select a voice")
            return false
        },
    }));

    const [isDarkMode, setIsDarkMode] = useState(() => {
        return localStorage.getItem("light-mode") !== "lightmode";
    });

    useEffect(() => {
        const handleStorageChange = () => {
            setIsDarkMode(localStorage.getItem("light-mode") !== "lightmode");
        };

        // Add the event listener
        window.addEventListener("lightmodechanged", handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("lightmodechanged", handleStorageChange);
        };
    }, []);

    return (
        <>
            <Col>
                <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{alert}</strong></h4>

                <ReactPlayer url={selectedVoiceUrl}
                    playing={isVoicePreview}
                    controls={false}
                    height='0px'
                    width='0px'
                    className="podcastPlayer"
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                />

                {props.isVoicesLoaded ?
                    <>
                        {props.dropdownVoices.length > 0 &&
                            <>

                                <Row>
                                    <Col className="text-center  text-white pb-2">
                                        <h4>Selected voice: {selectedVoice != null ? selectedVoice : "No selection"}</h4>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row className="justify-content-center">
                            <Col style={{ maxHeight: "50vh", overflow: "auto" }}>
                                {props.dropdownVoices.length > 0 ? (

                                    <Row style={{ overflowY: "auto" }}>
                                        {props.dropdownVoices.map((e, index) => {
                                            return (
                                                <Col sm="6">
                                                    <VoiceCard voiceData={e} index={index} handlePreviewVoice={handlePreviewVoice} handleSelectVoice={handleSelectVoice} showId={false} selectedVoice={selectedVoice} isAdVoice={true} colorSchemeTheme={isDarkMode ? "dark" : "light"} />
                                                </Col>
                                            )
                                        })}
                                    </Row>


                                ) :
                                    <Row>
                                        <Col className="text-center">
                                            <h4>No voices found, please refresh or try again later</h4>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </> : <Row>
                        <Col className="text-center text-white">
                            <h4>Loading voices</h4>
                            <Spinner style={{ color: "#F33B30" }} />
                        </Col>
                    </Row>
                }
            </Col>
        </>
    );
});

export default BStep2;
