/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation } from "react-router-dom";

import routes from "routes.js";
import LightNavbar from "../components/Navbars/LightNavbar";
import FooterLight from "../components/Footer/FooterLight";

var ps;

function SiteLayout() {
    const fullPages = React.useRef();
    const location = useLocation()
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(fullPages.current);
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/light") {
                return (
                    <Route path={prop.path} element={prop.component} key={key} exact />
                );
            } else {
                return null;
            }
        });
    };

    const isCompanyRoute = location.pathname.startsWith('/company');
    const isNewHomeOrWaitList = location.pathname.startsWith("/home") || location.pathname.startsWith("/waitlist")

    useEffect(() => {
        if (!location.pathname.startsWith('/admin')) {

            document.body.classList.add('light-mode');

        }
    }, [location.pathname])

    return (
        <>
            {!isCompanyRoute && !isNewHomeOrWaitList && <LightNavbar />}
            <div className="backgroundMainLight" ref={fullPages}>

                <Routes>{getRoutes(routes)}</Routes>
                <FooterLight fluid />

            </div>
        </>
    );
}

export default SiteLayout;
