const tooltipText = {
    TOPICS: {
        TOPICS: "Choose up to five (5) topics for your podcast",
        FEEDS: "Choose some sources for your podcast"
    },

    DETAILS: {
        START_DATE: "Choose the time your first episode will start. Each following episode will be released at the same time",
        DURATION: "Choose how long your podcast will be",
        REVIEW_PERIOD: "Choose how long you would like to approve the episode content before release",
        FREQUENCY: "Choose how often a new podcast will be generated",
        EPISODES: "If ticked, generate unlimited episodes",
        AMOUNT: "Enter the amount of episodes you'd like to generate",
        CLIENT: "Change your client here"
    },

    VOICE: {
        LANGUAGE: "Choose a language and a voice for your podcast",
    },

    NAME: {
        PODCAST_NAME: "Choose a name to identify your podcast",
        DESCRIPTION: "Choose a description to help others understand the topics and content",
        IMAGE: "Choose an image for your podcast",
    },
    // Above are wizard steps
    ADVERTS: {
        ADVERT_NAME: "Please give a name to your advert",
        ADVERT_DESCRIPTION: "You can give a description to your advert",
        MP3: "The audio for your advert",
        BUDGET: "Your budget for the advert",
        CPM_PRICES: "Leave 0 to ignore the slot"
    },

    EDIT_PODCAST: {
        NAME: "Change the name of your podcast",
        DESCRIPTION: "Change the description of your podcast",
        DURATION: "Change the duration of future generated podcasts",
        FREQUENCY: "Change how often a podcast is generated",
        REVIEW_PERIOD: "Change how long you have to review before generation",
        TOPICS: "Change the topics in your podcast",
        NEXT_RELEASE_DATE: "Change the release date of your next episode",
        IMAGE: "Change the image for your podcast"

    }

}

export default tooltipText