import React, { useState, useEffect } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from 'axios';

import "assets/css/custom-css.css";

function StripeGenericPaymentForm(props) {
    const [success, setSuccess] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [isLightMode, setIsLightMode] = useState(localStorage.getItem("light-mode") === "lightmode");

    const CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
            base: {
                iconColor: "#F33B30",
                color: !isLightMode ? "#fff" : "#000",
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: !isLightMode ? "#fff" : "#000" },
                "::placeholder": { color: !isLightMode ? "#fff" : "#000" },
            },
            invalid: {
                iconColor: "#F33B30",
                color: "white"
            }
        }
    };

    useEffect(() => {
        // Listen for changes in the "light-mode" setting in localStorage
        const handleStorageChange = () => {
            setIsLightMode(localStorage.getItem("light-mode") === "lightmode");
        };

        // Add the event listener
        window.addEventListener("lightmodechanged", handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("lightmodechanged", handleStorageChange);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const clientSecret = props.baseObject.paymentClientSecret;
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                //billing_details: {
                //    name: 'Cardholder Name',
                //},
            },
        });

        if (!error) {
            if (!props.isAdSpend) {
                props.renewCredits();
            } else {
                props.handlePurchase();
            }
        } else {
            console.error(error.message);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <fieldset className="FormGroup stripeCustom stripeMove">
                    <div className="FormRow">
                        <CardElement options={CARD_OPTIONS} />
                    </div>
                </fieldset>
                <div className="d-flex justify-content-center">
                    <button type="submit" className="buttonStyle buttonPrimary mt-4">Pay</button>
                </div>
            </form>
        </>
    );
}

export default StripeGenericPaymentForm;
