import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const MyModal = ({ isOpen, toggle, children, title, buttonAction, width, height, styleProp, nextText }) => {

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ width: width, maxWidth: width }}>
            <ModalHeader style={{borderBottom: "none"} }>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="ml-auto flex-grow-1 labelText">
                        {title}
                    </div>
                    <p onClick={toggle} style={{ cursor: "pointer" }} className="labelText"><i className="nc-icon nc-simple-remove" /></p>
                </div>
                
            </ModalHeader>
            <ModalBody className="reviewInput" style={{ maxHeight: height, minHeight: height, ...styleProp } } >
                {/* Content of your modal */}
                {children}

            </ModalBody>
            <ModalFooter style={{borderTop: "none"}} className="p-3">
                <button className="buttonStyle buttonSecondary" onClick={toggle}>
                    Close
                </button>
                <button className="buttonStyle buttonPrimary" type="submit" onClick={() => buttonAction()}>
                    {nextText ? nextText : "Save" }
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default MyModal;