import { Row, Col, FormGroup, Input, Label, Spinner } from "reactstrap"
import { useEffect, useState } from "react"
import GeneratedAd from "../AdWizardComps/GeneratedAd"
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import WizardPlayer from "../AdWizardComps/audioEditorComp/WizardPlayer";

const WizardAdCreate = (props) => {
    const [isExactText, setIsExactText] = useState(false)
    const [selectedAd, setSelectedAd] = useState(0)

    const fileTypes = ["MP3", "WAVE", "OGG"]
    const [file, setFile] = useState(null)
    const handleFileChange = (uploadedFile) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1]
            setFile(base64String);
            props.setBaseObject({...props.baseObject, uploadedBase64: base64String})
        };

        if (uploadedFile) {
            reader.readAsDataURL(uploadedFile); // Convert the file to base64
        }
    };

    const [singeSelectDuration, setSingleSelectDuration] = useState({ label: "30 seconds", id: 6 })
    const [durations, setDurations] = useState()
    const handleDurationChange = (value) => {
        setSingleSelectDuration(value)
        props.setBaseObject({ ...props.baseObject, length: value.id })
    }

    const handleSelectAd = (id) => {
        setSelectedAd(id)
        props.setBaseObject({ ...props.baseObject, selectedContent: id })
    }

    const generateDurations = () => {
        const options = [];

        options.push(
            { label: "30 seconds", id: 6 }
        )
        setDurations(options);
    };

    useEffect(() => {
        generateDurations()
    }, [])

    useEffect(() => {
        if (props.baseObject?.content && selectedAd === 0) {
            setSelectedAd(props.baseObject.content[0].id)
        }
    }, [props.baseObject?.content])

    const handleSetGeneration = (isExact) => {
        setIsExactText(isExact)
        props.setBaseObject({ ...props.baseObject, isExactScript: isExact })
    }

    useEffect(() => {
        if (!props.isGenerate) {
            if (file) {
                props.setIsOwnAdvert(true)
            } else {
                props.setIsOwnAdvert(false)
            }
        } else {
            props.setIsOwnAdvert(false)
        }
    }, [props.isGenerate, file])

    useEffect(() => {
        if (props.isGenerate) {
            props.setBaseObject({ ...props.baseObject, uploadedBase64: null})
        }
    }, [props.isGenerate])

    useEffect(() => {
        if (props.baseObject.uploadedBase64 != null) {
            setFile(props.baseObject.uploadedBase64)
        }
    }, [props.baseObject])

    return (
        <div className="pb-3">
            <Col>
                <Row className="justify-content-between mt-4">
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${props.isGenerate && "fadedKlaxonRed"} borderLight`} sm="" onClick={() => props.setIsGenerate(true)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Generate Advert</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Use Klaxon.AI to generate an advert for you.</p>
                    </Col>
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${!props.isGenerate && "fadedKlaxonRed"} borderLight`} sm="" onClick={() => props.setIsGenerate(false)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Upload Advert</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Use an existing advert.</p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {props.isGenerate ? <div style={{ width: "100%" }}>

                        Generate Advert

                        <Row>
                            <Col sm="6">
                                <FormGroup className="">
                                    <Label className="labelText">Length</Label>

                                    <Select
                                        className="react-select "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        value={singeSelectDuration}
                                        onChange={(value) => handleDurationChange(value)}
                                        options={durations}

                                    />

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" style={{ paddingRight: 15 }}>
                                <FormGroup className="">
                                    <Label className="labelText">Script</Label>

                                    <Input
                                        className="inputStyle placeholderWhite reviewInput"
                                        placeholder="Enter script"
                                        type="textarea"
                                        value={props.baseObject.script}
                                        onChange={(e) => {
                                            const charLimit = isExactText ? 75 : 250; // Set character limit based on isExactText

                                            if (e.target.value.length <= charLimit) {
                                                // Adjust textarea height
                                                e.target.style.height = 'auto'; // Reset height to auto
                                                e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
                                                e.target.style.maxHeight = '200px'; // Remove maxHeight limit;
                                                e.target.style.overflowY = 'auto'; // Enable vertical scrollbar when content exceeds maxHeight
                                                e.target.style.overflowX = 'auto'; // Enable horizontal scrollbar if content overflows horizontally
                                                e.target.style.setProperty('border', '1px solid transparent', 'important');
                                            } else {
                                                e.target.style.setProperty('border', '1px solid #F33B30', 'important');
                                                return;
                                            }

                                            props.setBaseObject({ ...props.baseObject, script: e.target.value });
                                        }}
                                        style={{ minHeight: 100 }}
                                        onBlur={(e) => {
                                            e.target.style.setProperty('border', '1px solid transparent', 'important');
                                        }}
                                    />

                                    <p style={{ margin: 0, textAlign: "right" }}>{props.baseObject.script ? props.baseObject.script.length : 0}/{isExactText ? 75 : 250 }</p>
                                    <div style={{ display: "flex", gap: 15 }}>
                                        <FormGroup check>
                                            <Label check className="labelText">
                                                <Input type="checkbox" checked={!isExactText} onClick={() => handleSetGeneration(false)} />
                                                <span className="form-check-sign" />
                                                Suggest Text Using Klaxon.AI
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check className="labelText">
                                                <Input type="checkbox" checked={isExactText} onClick={() => handleSetGeneration(true)} />
                                                <span className="form-check-sign" />
                                                Use Exact Text
                                            </Label>
                                        </FormGroup>
                                    </div>

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                {props.baseObject.id === 0 &&
                                    (
                                        props.isGenerating ?
                                            <button type="submit" disabled style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />
                                                GENERATE ADVERT (1 CREDIT) <Spinner size="sm" />
                                            </button>
                                            :
                                            <button onClick={() => props.handleAddAdvert()} type="submit" style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />
                                                GENERATE ADVERT (1 CREDIT)
                                            </button>
                                    )
                                }

                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="mt-2 mb-2">
                                    Generated Ads
                                </div>
                                {!props.baseObject?.content ?
                                    <div style={{ width: "100%", backgroundColor: "#F33B30", border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", padding: 10, margin: "auto", display: "flex", flexDirection: "column", gap: 10 }}>
                                        <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                            <i className="fa fa-info-circle" />
                                            Your Generated Ads
                                        </div>
                                        <div>Your Klaxon.AI ads will appear here once you have generated them.</div>
                                    </div>
                                    :
                                    <div>
                                        {props.baseObject?.content && props.baseObject?.content.length > 0 &&
                                            props.baseObject?.content.map((ad, index) => {
                                                return (
                                                    <Row key={index} >
                                                        <Col style={{ padding: 0, display: "flex", marginTop: 15, justifyContent: "flex-end" }}>
                                                            <FormGroup check>
                                                                <Label check >
                                                                    <Input type="checkbox" checked={selectedAd === ad.id} onClick={() => handleSelectAd(ad.id)} />
                                                                    <span className="form-check-sign" />
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="11">
                                                            <GeneratedAd advert={ad} setModalPage={props.setModalPage} regenerateContent={props.regenerateContent} handlePreviewVoice={props.handlePreviewVoice} voices={props.voices} music={props.music} isFirst={true} singleLoadId={props.singleLoadId} loadingAudio={props.loadingAudio} />

                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </Col>
                        </Row>

                    </div>
                        :
                        <div style={{ width: "100%" }}>
                            {!file ?
                                <Col>
                                    <div>Upload Advert</div>
                                    <div style={{ backgroundColor: "" }}>
                                        <FileUploader handleChange={handleFileChange} name="file" types={fileTypes} classes="wideUpload klaxonColorUpload" label="Click to upload or drag and drop" />
                                    </div>
                                </Col> :
                                <Col className="mt-4 mb-4 pb-4">
                                    <div>Preview Advert</div>
                                    <div>
                                        <WizardPlayer fileURL={file} />
                                    </div>
                                </Col>
                            }

                        </div>
                    }

                </Row>


            </Col>
        </div>
    )

}

export default WizardAdCreate 