import React, { useState } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Navbar,
    NavItem,
    Nav,
    Container,
    Col
} from "reactstrap";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import apiCall from "../../utils/apiCall";
import apiConfig from "config/apiConfig"

function AdminNavbar(props) {
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [color, setColor] = React.useState("navbar-transparent");
    const location = useLocation();
    const [menuType, setMenuType] = React.useState(0)
    const [isPodcastPage, setIsPodcastPage] = React.useState(false)

    const getUserMenuType = () => {
        let getMenuType = localStorage.getItem("accountType")
        if (getMenuType === undefined) {
            getMenuType = 0
        }
        setMenuType(Number(getMenuType))
    }

    const toggleMenuType = () => {
        const newMenuType = menuType === 0 ? 1 : 0
        localStorage.setItem("accountType", newMenuType)
        getUserMenuType()
        window.dispatchEvent(new Event("accountTypeChanged"))
    }

    React.useEffect(() => {
        getUserMenuType()
    }, [])

    React.useEffect(() => {
        setIsPodcastPage(
            location.pathname === "/admin/podcasts" || location.pathname === "/admin/dashboard" || location.pathname === "/admin/ads"
        )
    }, [location])

    const navigation = useNavigate();

    const handleLogout = () => {
        Cookies.remove('userData');
        navigation("/auth/login");
    }

    const handleNavigateProfile = () => {
        navigation("/admin/profile")
    }

    const handleNavigateManage = () => {
        navigation("/admin/manage-businesses")
    }

    const handleNavigateCreatePodcast = () => {
        navigation("/admin/create-podcast")
    }

    const handleNavigateCreateAdvert = () => {
        navigation("/admin/create-ad")
    }

    const handleNavigatePodcastPlayer = () => {
        navigation("/home")
    }

    React.useEffect(() => {
        window.addEventListener("resize", updateColor);
    });
    React.useEffect(() => {
        if (
            window.outerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }, [location]);
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
            setColor("navbar-transparent");
        } else {
            setColor("navbar-transparent");
        }
    };
    // this function opens and closes the sidebar on small devices
    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setSidebarOpen(!sidebarOpen);
    };

    const [clientData, setClientData] = React.useState([])
    const [clientSelect, setClientSelect] = React.useState({})
    const getClients = async () => {
        try {
            const resp = await apiCall(
                apiConfig.CLIENT.GET_ALL,
                "get"
            );
            if (resp.status === 200) {

                const dataCopy = resp.data.map((e) => {
                    return ({
                        value: e.id, label: e.name
                    })
                })
                
                dataCopy.unshift({ value: 0, label: "All" })
                setClientData(dataCopy)
                
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [userBalance, setUserBalance] = useState(0)
    const getUserBalance = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_USER_BALANCE,
                "get"
            );
            if (resp.status === 200) {
                setUserBalance(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    React.useEffect(() => {
        const handleGetUserBalance = () => getUserBalance();

        // Add the event listener when the component mounts
        window.addEventListener("balanceUpdated", handleGetUserBalance);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("balanceUpdated", handleGetUserBalance);
        };

    }, [])

    const handleClientSelect = (event) => {
        setClientSelect(event)
        localStorage.setItem("cId", event.value)
        window.dispatchEvent(new Event("clientChanged"))
    }

    const handleSelectClient = () => {
        let id = localStorage.getItem("cId")

        if (id === undefined) {
            return
        }
        id = Number(id)

        const findItem = clientData.find((e) => e.value === id)
        setClientSelect(findItem)
    }

    React.useEffect(() => {
        getClients()
        getUserBalance()
    }, [])

    React.useEffect(() => {
        if (clientData.length > 0) {
            handleSelectClient()
        }
    }, [clientData])


    return (
        <>
            <Navbar
                className={classnames("navbar-absolute fixed-top navbar-transparent")}
                expand="lg"
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                       
                        <div
                            className={classnames("navbar-toggle", {
                                toggled: sidebarOpen,
                            })}
                        >
                            <button
                                className="buttonPrimary"

                                onClick={toggleSidebar}
                                style={{ borderRadius: 180, color: "white", fontWeight: "bold", padding: 5, minWidth: 40, minHeight: 40, border: "none", display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                {!sidebarOpen ? <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-regular" /> : <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />}
                                
                            </button>
                        </div>
                        {isPodcastPage && menuType === 1 ?
                            <>
                                <h4 className="text-white" style={{margin: 0}}>Business:</h4>
                            <Col style={{width: 300} }>
                                    <Select 
                                        className="react-select "
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        options={clientData}
                                        onChange={handleClientSelect}
                                        value={clientSelect}
                                    />
                                </Col>
                            </>: null
                        }
                        <>
                            {isPodcastPage &&
                                <u className="text-white" style={{ cursor: "pointer" }} onClick={toggleMenuType}>Switch to {menuType === 1 ? "personal" : "business"}</u>    
                            }
                        </>
                        
                    </div>


                    <Nav>
                        <NavItem className="listenButton">
                            <button className="mr-2 buttonStyle buttonPrimary" type="submit" onClick={handleNavigatePodcastPlayer}>Listen</button>
                        </NavItem>
                        <NavItem className="createButton">
                            <button className="mr-2 buttonStyle buttonPrimary" type="submit" onClick={handleNavigateCreatePodcast}>Create Podcast</button>
                        </NavItem>
                        <NavItem className="createButton">
                            <button className="mr-2 buttonStyle buttonPrimary" type="submit" onClick={handleNavigateCreateAdvert}>Create Advert</button>
                        </NavItem>
                        <UncontrolledDropdown nav style={{ display: "flex", alignItems: "center", border: 0 }}>
                            <DropdownToggle
                                className="noBorder"
                                aria-haspopup={false}
                                caret
                                color="default"
                                data-toggle="dropdown"
                                id="navbarDropdownMenuLink"
                                nav
                            >
                                <i className="nc-icon nc-single-02" />
                                
                            </DropdownToggle>
                            <DropdownMenu
                                persist
                                aria-labelledby="navbarDropdownMenuLink"
                                right
                            >
                               
                                <DropdownItem

                                    onClick={handleNavigateProfile}
                                >
                                    Profile
                                </DropdownItem>
                                {menuType === 1 &&
                                    <DropdownItem

                                        onClick={handleNavigateManage}
                                    >
                                        Manage Businesses
                                    </DropdownItem>    
                                }
                                <DropdownItem
                                    className="listenButtonSmall"
                                    onClick={handleNavigatePodcastPlayer}
                                >
                                    Listen
                                </DropdownItem>
                                <DropdownItem
                                    onClick={handleLogout}
                                >
                                    Logout
                                </DropdownItem>
                                <DropdownItem
                                >
                                    Balance: &pound;{userBalance}
                                </DropdownItem>
                                <DropdownItem
                                    onClick={props.toggleLightMode}
                                >
                                    toggleLightMode
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
}

export default AdminNavbar;
