import { useState } from "react"
import { Row, Col, Input, FormGroup, Spinner } from "reactstrap"
import GeneratedAd from "../AdWizardComps/GeneratedAd"
import WizardPlayer from "../AdWizardComps/audioEditorComp/WizardPlayer"

const WizardAdLanguage = (props) => {
    const [isTranslation, setIsTranslation] = useState(true)
    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [currentSelectedLang, setCurrentSelectedLang] = useState(null)
    const [isTranslationGenerated, setIsTranslationGenerated] = useState(false)
    const [generatedTranslation, setGeneratedTranslations] = useState(null)


    const handleLanguageSelect = (lang) => {
        const findLang = props.languages.find((langFromList) => langFromList.value == lang.target.value)
        setCurrentSelectedLang(findLang)
    }

    const handleAddLanguage = () => {
        if (!currentSelectedLang)
            return

        const alreadySelected = selectedLanguages.some(
            (lang) => lang.value === currentSelectedLang.value
        );
        if (alreadySelected)
            return

        const copySelected = [...selectedLanguages]
        copySelected.push(currentSelectedLang)
        setSelectedLanguages(copySelected)
        setCurrentSelectedLang(null)
    }

    const handleRemoveLanguage = () => {
        if (!currentSelectedLang)
            return

        const isNotSelected = !selectedLanguages.some(
            (lang) => lang.value === currentSelectedLang.value
        );
        if (isNotSelected)
            return

        const updatedSelectedLanguages = selectedLanguages.filter(
            (lang) => lang.value !== currentSelectedLang.value
        )

        setSelectedLanguages(updatedSelectedLanguages)
        setCurrentSelectedLang(null)
    };

    const addLanguagesToTranslations = (translations) => {
        return translations.map(translation => {
            // Use a regular expression to extract the language code from the URL
            const regex = /\/podcast-ads\/(\d+)-([a-z]{2})\.mp3/;
            const match = regex.exec(translation);

            // If a match is found, extract the language code
            const languageCode = match ? match[2] : null; // match[2] will contain the language code
            const language = props.languages.find((lang) => lang.value == languageCode).label

            return {
                url: translation,
                language: language,
            };
        });
    };

        console.log(props.languages)
    const handleGenerateTranslationClicked = async () => {
        const copyLang = [...selectedLanguages]
        const languageArr = copyLang.map((lang) => {
            return lang.value
        })
        const makeTranslations = await props.handleGenerateTranslations(languageArr)

        if (makeTranslations) {
            const translationsWithLanguages = await addLanguagesToTranslations(makeTranslations)
            setGeneratedTranslations(translationsWithLanguages)
            setSelectedLanguages([])
        }

        setIsTranslationGenerated(true)
    }



    return (
        <div className="">
            <Col>
                <Row className="justify-content-between mt-4">
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${isTranslation && "fadedKlaxonRed"} borderLight`} sm="" onClick={() => setIsTranslation(true)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Generate Language Translations</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Use Klaxon.AI to generate your advert in different languages.</p>
                    </Col>
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column" }} className={`${!isTranslation && "fadedKlaxonRed"} borderLight`} sm="" onClick={() => setIsTranslation(false)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>No Language Translations</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Don't create any translations.</p>
                    </Col>
                </Row>

                <Row className="mt-4 pb-5">
                    {isTranslation ? <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column" }} >
                            Translation Options
                            <span style={{ fontSize: 12 }}>You can skip this step if you don't want any translations</span>
                        </div>

                        {props.baseObject?.content && props.baseObject?.content.length > 0 &&
                            <div style={{ backgroundColor: "#404040", display: "", alignItems: "", justifyContent: "", gap: 15, width: "90%", margin: "auto", padding: 15 }} className="mt-4">
                            English
                                <WizardPlayer fileURL={props.baseObject.content.find((con) => con.id == props.baseObject.selectedContent).urls[0].url} />
                            </div>
                        }

                        <div>
                            <Row className="justify-content-center mt-4">
                                <Col sm="2">
                                    <FormGroup>
                                        <Input
                                            id="exampleSelect"
                                            multiple
                                            name="select"
                                            type="select"
                                            onClick={handleLanguageSelect}
                                            onDoubleClick={handleAddLanguage}
                                            style={{ minHeight: 200 }}
                                        >
                                            {props.languages.map((lang) => {
                                                if (selectedLanguages.some(selectedLang => selectedLang.value === lang.value)) {
                                                    return null
                                                }
                                                return (
                                                    <option key={lang.value} value={lang.value}>
                                                        {lang.label}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="2">
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10, minHeight: 200 }}>
                                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", borderRadius: 7, minHeight: 35, maxHeight: 35, minWidth: 70, maxWidth: 70, cursor: "pointer" }} onClick={handleAddLanguage} className="smallAltButton">
                                            <i class="fas fa-chevron-right" style={{}} />
                                        </div>
                                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", borderRadius: 7, minHeight: 35, maxHeight: 35, minWidth: 70, maxWidth: 70, cursor: "pointer" }} onClick={handleRemoveLanguage} className="smallAltButton">
                                            <i class="fas fa-chevron-left" style={{}} />
                                        </div>

                                    </div>
                                </Col>
                                <Col sm="2">
                                    <FormGroup>

                                        <Input
                                            id="exampleSelect"
                                            multiple
                                            name="select"
                                            type="select"
                                            onClick={handleLanguageSelect}
                                            onDoubleClick={handleRemoveLanguage}
                                            style={{ minHeight: 200 }}
                                        >
                                            {selectedLanguages.map((lang) => {
                                                return (
                                                    <option value={lang.value}>{lang.label}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm="4" className="text-center">
                                    {selectedLanguages && selectedLanguages.length > 0 &&
                                        <button onClick={() => handleGenerateTranslationClicked()} type="submit" style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />
                                            GENERATE TRANSLATIONS ({selectedLanguages.length * 2} CREDITS)
                                            {props.isGenerating &&
                                                <Spinner size="sm" />
                                            }
                                        </button>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <Row className="mt-2">
                            <Col>
                                <div className="mt-2 mb-2">
                                    Generated Language Translations
                                </div>
                                {!isTranslationGenerated ?
                                    <div style={{ width: "100%", backgroundColor: "#F33B30", border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", padding: 10, margin: "auto", display: "flex", flexDirection: "column", gap: 10 }}>
                                        <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                            <i className="fa fa-info-circle" />
                                            Generated Language Translations
                                        </div>
                                        <div>Your selected translations will appear here once created.</div>
                                    </div> : <div>
                                        {generatedTranslation && generatedTranslation.length > 0 &&
                                            generatedTranslation.map((ad) => {
                                                return (
                                                    <GeneratedAd advert={ad.url} isLanguage={true} setModalPage={props.setModalPage} voices={props.voices} music={props.music} singleLoadId={props.singleLoadId} language={ad.language} />
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                        :
                        <div style={{ width: "100%", backgroundColor: "#F33B30", border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", padding: 10, margin: "auto", display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                <i className="fa fa-info-circle" />
                                No Language Translations
                            </div>
                            <div>Only the default language will be used (English).</div>
                        </div>}
                </Row>
            </Col>
        </div>
    )
}

export default WizardAdLanguage 