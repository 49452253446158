import React, { useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import {
    Button,
    FormGroup,
    Input,
    Row,
    Col,
    Label,
    UncontrolledTooltip,
    Spinner
} from "reactstrap";

import tooltipText from "../../../config/tooltipText";
import getJWTToken from "../../../config/jwtToken";


const Step1 = React.forwardRef((props, ref) => {

    const [typeChosen, setTypeChosen] = useState(false)
    const [alert, setAlert] = useState(null)
    const [topicArray, setTopicArray] = useState([])
    const [topicBar, setTopicBar] = useState("")
    const [selectedPodcastType, setSelectedPodcastType] = useState(null)
    const [isValidating, setIsValiding] = useState(false)

    const validatePodcast = async () => {
        setAlert(null)
        if (props.baseObject.topics.length > 0) {
            setIsValiding(true)
            try {
                const resp = await apiCall(
                    apiConfig.PODCAST.VALIDATE_CONTENT + `?userId=${getJWTToken().decoded.nameid}`,
                    "post", props.baseObject
                );
                if (resp.status === 204) {
                    setIsValiding(false)
                    setAlert("No content found for selected topics, please try again")
                } else if (resp.status === 200) {
                    setIsValiding(false)
                    return true
                }
            } catch (error) {
                console.error(error)
                setIsValiding(false)
                return false
            }
        } else {
            setAlert("Please enter topic(s)")
            return false
        }
    };

    React.useImperativeHandle(ref, () => ({
        isValidated: async () => {

            if (selectedPodcastType === null) {
                setAlert("Please select a podcast type")
                return false
            }

            const podcastValid = await validatePodcast()
            if (!!podcastValid) {
                props.setTitleNumber(props.titleNumber + 1)
                return true
            } else {
                setAlert("These topics are invalid or you have not entered any topics")
                return false
            }
        },
    }));

    const handleAddTopic = () => {
        const allowedPattern = /^[a-zA-Z0-9\s]+$/;
        if (topicBar.trim() !== "" && allowedPattern.test(topicBar)) {
            if (topicArray.length !== 5) {
                setTopicArray(prevArray => {
                    const currentArray = [...prevArray, topicBar];
                    props.setBaseObject({ ...props.baseObject, topics: currentArray });
                    setTopicBar("");
                    setAlert(null)
                    return currentArray;
                });
            } else {
                setAlert("You can only have 5 topics maximum");
            }
        } else {
            setAlert("Empty topics and topics with special characters are not allowed");
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleAddTopic()
        }
    }


    const handleDeleteTopic = (id) => {
        setAlert(null)
        setTopicArray(prevArray => {
            const removedItem = prevArray.filter((_, i) => i !== id);
            props.setBaseObject({ ...props.baseObject, topics: removedItem });
            return removedItem;
        });
    }

    const handlePodcastType = (type) => {
        if (type === "single") {
            props.setBaseObject({ ...props.baseObject, frequencyId: 1, frequencyName: "Instant" })
            setSelectedPodcastType("Single Podcast")
        } else {
            props.setBaseObject({ ...props.baseObject, frequencyId: 0 })
            setSelectedPodcastType("Podcast Series")
        }
        setAlert(null)
        setTypeChosen(true)
        props.setTitleNumber(props.titleNumber + 1)
    }

    return (
        <>
            <Col className="justify-content-center">


                <Col className="mb-4">
                    <Row className="justify-content-center">
                        <button onClick={() => handlePodcastType("single")} className="mr-4 buttonStyle buttonPrimary" size="lg">Single Podcast</button>
                        <button onClick={() => handlePodcastType("multi")} className="ml-4 buttonStyle buttonPrimary" size="lg">Podcast series</button>
                    </Row>
                    {selectedPodcastType !== null && <p className="text-center text-white mt-4">Podcast type selected: <strong>{selectedPodcastType}</strong></p>}
                </Col>


                <>
                    <Row className="pt-4 justify-content-center">
                        {!isValidating ?
                            <Col sm="8">
                                <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{alert}</strong></h4>
                                <FormGroup className="justify-content-center">
                                    <Label>Topics</Label>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                        id="tooltip8089663901115252"
                                        size="sm"
                                        style={{ backgroundColor: "white" }}
                                    >
                                        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip8089663901115252"
                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                    >
                                        <p style={{ margin: 0 }}>{tooltipText.TOPICS.TOPICS}</p>
                                    </UncontrolledTooltip>
                                    <Row>
                                        <Col sm="8" style={{ alignItems: "center", justifyContent: "center", display: "flex", marginBottom: 10 }}><Input className="inputStyle placeholderWhite" placeholder="Enter a topic" value={topicBar} onChange={(e) => setTopicBar(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} /></Col>
                                        <Col sm="4"> <button className="buttonStyle buttonPrimary" onClick={handleAddTopic}>Add</button></Col>
                                    </Row>
                                    {topicArray.length > 0 &&
                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                            {topicArray.map((e, index) => (
                                                <div key={index} className="buttonPrimary" style={{ padding: "5px", borderRadius: "5px", boxSizing: "border-box", display: "flex", justifyContent: "space-between", marginBottom: "5px", marginRight: "5px" }}>
                                                    <p style={{ margin: 0, marginRight: 5, color: "white", fontWeight: "bold" }}>{e}</p>
                                                    <p style={{ margin: 0, color: "white", cursor: "pointer" }} onClick={() => handleDeleteTopic(index)}>x</p>
                                                </div>
                                            ))}
                                        </div>
                                    }


                                </FormGroup>

                            </Col> : <Col className="text-center">
                                <p className="text-center text-white mt-4">Validating your podcast</p>
                                <Spinner style={{ color: "#F33B30" }} />
                            </Col>
                        }
                    </Row>

                </>

            </Col>
        </>
    );
});

export default Step1;
