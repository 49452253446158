import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiConfig from "config/apiConfig";

import "assets/css/custom-css.css"
import Swal from "sweetalert2";
import klaxonLogo from "assets/img/klaxonImages/klaxonFull.png"

function ResetPassword() {
    const [error, setError] = useState("");
    const [isFocused, setIsFocused] = useState("")


    const [recoveryToken, setRecoveryToken] = useState(new URLSearchParams(location.search).get('token'))
    const [recoveryEmail, setRecoveryEmail] = useState(new URLSearchParams(location.search).get('email'))
    const [recoveryPassword, setRecoveryPassword] = useState("")
    const [confirmRecoveryPassword, setConfirmRecoveryPassword] = useState("")
    const navigation = useNavigate();

    const handleBlur = () => {
        setIsFocused("")
    }

    const handleLoginNavigation = () => {
        navigation("/auth/login")
    }

    React.useEffect(() => {
        document.body.classList.toggle("register-page");
        return function cleanup() {
            document.body.classList.toggle("register-page");
        };
    });

    const handleResetAction = async (e) => {
        e.preventDefault();

        if (recoveryPassword === confirmRecoveryPassword && recoveryPassword.length > 0) {
            let url = `${apiConfig.baseUrl}${apiConfig.ACCOUNT.RESET_PASSWORD}`;
            let resetObject = {
                email: recoveryEmail,
                resetToken: recoveryToken,
                newPassword: confirmRecoveryPassword
            }

            await axios
                .post(url, resetObject)
                .then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Password reset",
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                container: 'custom-swal-container',
                                popup: 'custom-swal-popup',
                                title: 'custom-swal-title',
                                icon: 'custom-swal-icon',
                            },
                        });
                        setTimeout(navigation("/auth/login"), 1500)
                    }
                    else {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Something went wrong",
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                container: 'custom-swal-container',
                                popup: 'custom-swal-popup',
                                title: 'custom-swal-title',
                                icon: 'custom-swal-icon',
                            },
                        });
                    }
                })
                .catch((err) => {
                    //setError(err.response.data);
                });
        }
    }

    const handleNavigateHome = () => {
        navigation("/home")
    }

    return (
        <div className="register-page">
            <Container style={{
                zIndex: 2,
                position: "relative"
            }}>
                <Row>
                    <Col className="ml-auto" lg="5" md="6">
                        <Form action="" className="form" method="">
                            <Card className="card-login p-4 formStyle card-signup" style={{ height: 600 }}>
                                <div style={{ color: "white", position: "absolute", top: "3%", right: "5%", justifyContent: "center", alignItems: "center", display: "flex", cursor: "pointer" }} onClick={handleNavigateHome}>
                                    <i className="nc-icon nc-minimal-left" style={{ marginRight: 10 }} />
                                    <p style={{ marginBottom: 0 }}>Back to site</p>
                                </div>
                                <CardHeader className="d-flex justify-content-center">

                                    <img src={klaxonLogo} style={{ width: "80%" }} />

                                </CardHeader>


                                <CardBody style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>


                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "newPassword" ? "fakeHoverEffect" : ""}>


                                        <Input
                                            placeholder="New Password"
                                            type="password"
                                            value={recoveryPassword}
                                            onChange={(e) => setRecoveryPassword(e.target.value)}
                                            className="inputRemoveHover inputStyle placeholderWhite"
                                            onFocus={() => setIsFocused("newPassword")}
                                            onBlur={handleBlur}

                                        />
                                    </InputGroup>

                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "confirmNewPassword" ? "fakeHoverEffect" : ""}>


                                        <Input
                                            placeholder="Confirm Password"
                                            type="password"
                                            value={confirmRecoveryPassword}
                                            onChange={(e) => setConfirmRecoveryPassword(e.target.value)}
                                            className="inputRemoveHover inputStyle placeholderWhite"
                                            onFocus={() => setIsFocused("confirmNewPassword")}
                                            onBlur={handleBlur}

                                        />
                                    </InputGroup>

                                    <p className="text-center"></p>
                                    <p className="text-center text-red">{error}</p>
                                </CardBody>
                                <CardFooter style={{ marginTop: 0, display: "flex", alignItems: "center", flexDirection: "column" }}>
                                    <button className="buttonPrimary buttonStyle" onClick={handleResetAction} style={{ width: "90%" }}>
                                        Reset Password
                                    </button>

                                    <p className="text-center text-white" style={{ marginBottom: 0, marginTop: 16 }}>Want to login? <u onClick={handleLoginNavigation} style={{
                                        color: "white",
                                        textDecoration: "underline white",
                                        cursor: "pointer"
                                    }}>Click here</u></p>

                                </CardFooter>

                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <div
                className=""
                style={{
                    backgroundImage: `url(${require("assets/img/bg/bg.jpg")})`,
                    position: "absolute",
                    zIndex: 1,
                    display: "block",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                    backgroundSize: "cover"
                }}
            />
        </div>
    );
}

export default ResetPassword;
