console.log('apiUrl', process.env.REACT_APP_API_URL);

const apiConfig = {
    baseUrl: process.env.REACT_APP_API_URL,

    ACCOUNT: {
        LOGIN: "Account/Login",
        FORGOT_PASSWORD: "Account/ForgotPassword",
        REFRESH_TOKEN: "Account/RefreshToken",
        CHANGE_PASSWORD: "Account/ChangePassword",
        RESET_PASSWORD: "Account/ResetPassword"
    },

    // User
    USER: {
        REGISTER: "User/Register",
        GET_SINGLE: "User/GetSingle",
        UPDATE: "User/Update",
        SAVE_USER_AVATAR: "User/SaveUserAvatar",
        GET_USER_AVATAR: "User/GetUserAvatar",
        VERIFY_CODE: "User/VerifyCode",
        RESEND_VERIFICATION_CODE: "User/ResendVerificationCode",
        GET_USER_BALANCE: "User/GetUserBalance"
    },

    USER_SUBSCRIPTION: {
        GET_SUBSCRIPTIONS: "UserSubscription/GetSubscriptions",
        UPDATE: "UserSubscription/Update",
        CANCEL_SUBSCRIPTION: "UserSubscription/CancelSubscription"
    },

    //get all podcast
    PODCAST: {
        GET_ALL: "Podcast/GetAll",
        GET_ALL_BY_CLIENT: "Podcast/GetAllByClient",
        CREATE: "Podcast/Create",
        CONVERT: "PodcastEpisode/Convert",
        UPDATE_SECTIONS: "PodcastEpisode/UpdateSections",
        DOWNLOAD: "PodcastEpisode/Download",
        GET_FREQUENCIES: "Podcast/GetFrequencies",
        GET_DURATIONS: "Podcast/GetDurations",
        GET_REVIEW_PERIODS: "Podcast/GetReviewPeriods",
        VALIDATE_CONTENT: "Podcast/ValidateContent",
        DELETE: "Podcast/Delete",
        GET_SINGLE: "Podcast/GetSingle",
        UPDATE: "Podcast/Update",
    },

    // Podcast Episode
    PODCAST_EPISODE: {
        GET_SINGLE: "PodcastEpisode/GetSingle",
        GET_URLS: "PodcastEpisode/GetUrls",
        CHECK_STATUS: "PodcastEpisode/CheckPodcastEpisodeStatus",
        BLOCK: "PodcastEpisode/Block",
        GET_ESTIMATED_PODCAST_LENGTH: "PodcastEpisode/GetEstimatedPodcastLength",
        ADD_BUSINESS_EPISODE: "PodcastEpisode/AddBusinessEpisode",
        UPDATE_BUSINESS_EPISODE: "PodcastEpisode/UpdateBusinessEpisode",
        GET_BUSINESS_EPISODE: "PodcastEpisode/GetSingleBusiness",
        GENERATE_SCRIPTS: "PodcastEpisode/GenerateScripts",
        CHECK_EPISODE_GENERATION_STATUS: "PodcastEpisode/CheckEpisodeGenerationStatus",
        CONVERT: "PodcastEpisode/Convert",
        DOWNLOAD_BUSINESS_EPISODE: "PodcastEpisode/DownloadBusinessEpisode",
        SHARE_BUSINESS_EPISODE: "PodcastEpisode/ShareBusinessEpisode"
    },

    PODCAST_EPISODE_SECTIONS: {
        GET_ALL: "PodcastEpisodeSection/GetAll",
        GET_SCRIPT: "PodcastEpisodeSection/GetScript"
    },

    PODCAST_EPISODE_PLAYS: {
        ADD_OR_UPDATE: "PodcastPlays/AddOrUpdate"
    },

    //content apis
    CONTENT_DATA: {
        FEED: "Feed/GetAll",
        TOPICS: "Topic/GetAll",
    },

    VOICE: {
        GET_LANGUAGES: "Voice/GetLanguages",
        GET_VOICES: "Voice/GetVoices",
        GET_PREVIEW: "Voice/PreviewVoice",
        TEXT_TO_SPEECH: "Voice/TextToSpeech",
        GET_EL_VOICES: "Voice/GetELVoices",
        GET_AZURE_VOICES: "Voice/GetAzureVoices",
        GET_VOICE_LABEL_OPTIONS: "Voice/GetVoiceLabelOptions",
        TRANSLATE_VOICE: "Voice/TextToSpeechTranslated"
    },

    // Home
    HOME: {
        GET_BY_TOPIC: "Home/GetByTopic",
        GET_TRENDING: "Home/GetTrending",
        GET_SUGGESTED: "Home/GetSuggested",
        GET_SUGGESTED_TOPICS: "Home/GetSuggestedTopics",
        CONTACT_US: "Home/ContactUs",
        SEARCH_PODCASTS: "Home/SearchPodcasts"
    },

    // Mailing List
    MAILING_LIST: {
        REGISTER_INTEREST: "MailingList/RegisterInterest",
        JOIN_CONTACT_LIST: "MailingList/JoinContactList"
    },

    ADVERT: {
        GET_ALL: "Advert/GetAll",
        GET_SINGLE: "Advert/GetSingle",
        ADD: "Advert/Add",
        UPDATE: "Advert/Update",
        DELETE: "Advert/Delete",
        ADD_TRANSLATIONS: "Advert/AddTranslations",
        GET_POSITION_TYPES: "Advert/GetPositionTypes",
        RECORD_PLAY: "Advert/RecordPlay",
        GET_ADVERT_TARGETING: "Advert/GetAdvertTargeting",
        GET_CATEGORIES: "Advert/GetCategories",
        REGENERATE_CONTENT: "Advert/RegenerateContent",
    },

    DASHBOARD: {
        GET_DASHBOARD_DATA: "Dashboard/GetDashboardData",
        GET_DASHBOARD_DATA_BUSINESS: "Dashboard/GetDashboardDataBusiness"
    },

    CLIENT: {
        GET_ALL: "Client/GetAll",
        GET_SINGLE: "Client/GetSingle",
        GET_SINGLE_BY_SLUG: "Client/GetSingleBySlug",
        ADD: "Client/Add",
        UPDATE: "Client/Update",
        DELETE: "Client/Delete"
    },

    MUSIC: {
        GET_ALL: "Music/GetAll",
        GET_SINGLE: "Music/GetSingle",
        ADD: "Music/Add",
        UPDATE: "Music/Update",
        DELETE: "Music/Delete"
    },

    MUSIC_TAG: {
        GET_ALL: "MusicTag/GetAll",
        GET_SINGLE: "MusicTag/GetSingle",
        ADD: "MusicTag/Add",
        UPDATE: "MusicTag/Update",
        DELETE: "MusicTag/Delete"
    },

    TRANSACTION: {
        GET_SINGLE: "Transactions/GetSingle",
        GET_ALL_TRANSACTION_PRODUCTS: "Transactions/GetAllTransactionProducts",
        INIT_PURCHASE: "Transactions/InitPurchase",
        CONFIRM_PURCHASE: "Transactions/ConfirmPurchase",
    },

    USER_CREDIT: {
        GET_CREDITS: "UserCredit/GetCredits",
        USE_CREDITS: "UserCredit/UseCredits"
    }
};

export default apiConfig;