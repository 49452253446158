import { CardBody, Row, Col, FormGroup, Input, Button, Label, UncontrolledTooltip } from "reactstrap"

import { useState, useEffect } from 'react'
import tooltipText from "../../config/tooltipText";
import WizardPlayer from "../Wizards/AdWizardComps/audioEditorComp/WizardPlayer";
function CreateAd(props) {
    
    const [audioUrls, setAudioUrls] = useState([])

    const handleUrls = () => {
        const chosenContent = props.baseObject.content.find((con) => con.id == props.baseObject.selectedContent).urls
        setAudioUrls(chosenContent)
    }

    useEffect(() => {
        if (props.baseObject?.content && props.baseObject?.content.length > 0) {
            handleUrls()
        }
    }, [props.baseObject])
    
    return (
        <>
            <div className="content">
                <CardBody>
                    <Row>
                        <Col>

                            <FormGroup>
                                <Label className="labelText">Name</Label>
                                <Button
                                    className="btn-icon dropdownIgnore tooltipHover"
                                    color="danger"
                                    id="tooltip808966390111"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390111"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                    
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.ADVERTS.ADVERT_NAME}</p>
                                </UncontrolledTooltip>
                                <Input className="inputStyle placeholderWhite" type="text" value={props.baseObject.name} onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />
                                {props.missingFields.includes("name") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Please enter a name for the advert</p> : null }
                            </FormGroup>

                        </Col>

                        <Col>
                            

                            <FormGroup className="mr-4">
                                <Label className="labelText">Budget</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                   
                                    id="tooltip808966390111323"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390111323"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.ADVERTS.BUDGET}</p>
                                </UncontrolledTooltip>
                                <Input className="inputStyle placeholderWhite" placeholder="0" type="number" value={props.baseObject.budget} onChange={(e) => props.setBaseObject({ ...props.baseObject, budget: e.target.value })} />
                                {props.missingFields.includes("budget") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Budget must be more than 0</p> : null}
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {audioUrls && audioUrls.length > 0 &&
                                audioUrls.map((aud) => {
                                    return (
                                        <WizardPlayer fileURL={aud.url} changeScheme={true} />
                                    )
                                })    
                            }
                        </Col>
                    </Row>
                    
                </CardBody>
            </div>

        </>
    )
}

export default CreateAd