import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroup,
    Container,
    Col,
    Row,
} from "reactstrap";

//import logo from "assets/img/logo.png";
import { useNavigate } from "react-router-dom";
//import Swal from "sweetalert2";
import Cookies from "js-cookie";
import axios from "axios";
import apiConfig from "config/apiConfig";
import ReactBSAlert from "react-bootstrap-sweetalert"
import "assets/css/custom-css.css"
import klaxonLogo from "assets/img/klaxonImages/klaxonFull.png"

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isFocused, setIsFocused] = useState("")
    const [alert, setAlert] = useState(null)

    const navigation = useNavigate();

    const handleBlur = () => {
        setIsFocused("")
    }

    useEffect(() => {
        document.body.classList.toggle("login-page");
        return function cleanup() {
            document.body.classList.toggle("login-page");
        };
    });

    const handleLoginType = (type) => {
        localStorage.setItem("accountType", type)
        navigation("/admin/dashboard")
    }

    useEffect(() => {
        const cookie = Cookies.get("userData");
        if (cookie !== undefined) {
            let userobj = JSON.parse(cookie);
            if (userobj?.token) {
                navigation("/admin/dashboard");
            }
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError("")

        let obj = {
            email: email,
            password: password,
            rememberMe: false
        };

        let url = `${apiConfig.baseUrl}${apiConfig.ACCOUNT.LOGIN}`;

        await axios
            .post(url, obj,)
            .then((res) => {
                if (res?.data?.token) {
                    Cookies.set("userData", JSON.stringify(res?.data));
                    setAlert(
                        <ReactBSAlert
                            style={{ display: "block", marginTop: "-100px" }}
                            title="How would you like to use Klaxon today?"
                            onConfirm={() => handleLoginType(1)}
                            onCancel={() => handleLoginType(0)}
                            confirmBtnBsStyle="info"
                            cancelBtnBsStyle="info"
                            confirmBtnText="Business"
                            cancelBtnText="Personal"
                            showCancel
                            btnSize=""
                        >
                        </ReactBSAlert>
                    )
                } else {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Error logging in",
                        showConfirmButton: false,
                        timer: 1500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    });
                }
            })
            .catch((err) => {
                //setError(err.response.data);
                //SET ERROR HERE
                setError("Email and Password do not match")
            });
    };


    const handleCreateNavigation = () => {
        navigation("/auth/register");
    }

    const handleForgotPasswordNavigation = () => {
        navigation("/auth/forgot-password");
    }

    const handleNavigateHome = () => {
        navigation("/home")
    }



    return (
        <div className="login-page">
            {alert}
            <Container style={{
                zIndex: 2,
                position: "relative"
            }}>
                <Row>
                    <Col className="ml-auto" lg="5" md="6">
                        <Form action="" className="form" method="">
                            <Card className="card-signup p-4 formStyle" style={{ minHeight: 600 }}>
                                <div style={{ color: "white", position: "absolute", top: "3%", right: "5%", justifyContent: "center", alignItems: "center", display: "flex", cursor: "pointer" }} onClick={handleNavigateHome}>
                                    <i className="nc-icon nc-minimal-left" style={{ marginRight: 10 }} />
                                    <p style={{ marginBottom: 0 }}>Back to site</p>
                                </div>
                                <CardHeader className="d-flex justify-content-center">

                                    <img src={klaxonLogo} style={{ width: "80%" }} />

                                </CardHeader>
                                <CardBody style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "email" ? "fakeHoverEffect" : ""}>

                                        <Input
                                            placeholder="Email"
                                            type="email"
                                            value={email}
                                            autoComplete="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="inputRemoveHover placeholderWhite"
                                            onFocus={() => setIsFocused("email")}
                                            onBlur={handleBlur}
                                            style={{ background: "rgba(67, 65, 70, 0.5)", color: "white" }}
                                        />
                                    </InputGroup>
                                    <InputGroup style={{ width: "90%", justifyContent: "center", display: "flex" }} className={isFocused === "password" ? "fakeHoverEffect" : ""}>

                                        <Input
                                            placeholder="Password"
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            autoComplete="password"
                                            className="inputRemoveHover placeholderWhite"
                                            onFocus={() => setIsFocused("password")}
                                            onBlur={handleBlur}
                                            style={{
                                                background: "rgba(67, 65, 70, 0.5)", color: "white"
                                            }}
                                        />
                                    </InputGroup>
                                    <p className="text-center" onClick={handleForgotPasswordNavigation} style={{
                                        color: "white",
                                        textDecoration: "underline white",
                                        cursor: "pointer"
                                    }}>Forgot password</p>
                                    <p className="text-center" style={{ color: "white", fontWeight: 600 }}>{error}</p>
                                </CardBody>
                                <CardFooter style={{ marginTop: 0, display: "flex", alignItems: "center", flexDirection: "column" }}>

                                    <button className="buttonStyle buttonPrimary" style={{ width: "80%" }} onClick={handleLogin}>Login</button>
                                    <p className="text-center text-white" style={{ marginBottom: 0, marginTop: 16 }}>Don't have an account? <u onClick={handleCreateNavigation} style={{
                                        color: "white",
                                        textDecoration: "underline",
                                        cursor: "pointer",

                                    }}>Register here</u></p>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <div
                className=""
                style={{
                    backgroundImage: `url(${require("assets/img/bg/bg.jpg")})`,
                    position: "absolute",
                    zIndex: 1,
                    display: "block",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                    backgroundSize: "cover"
                }}
            />
        </div>
    );
}

export default Login;
